import { ConfigBrandType } from '@/packages/api/resources/v1/config/brand';
import Image from 'next/image';
import React from 'react';

type Props = {
  className?: string;
  brand: ConfigBrandType;
  width?: number;
  height?: number;
};

export const TenantLogo: React.FC<Props> = ({ className = '', brand, width = 130, height = 48 }) => {
  const src = brand.logoPath || '/images/logo.svg';

  return <Image src={src} className={className} width={width} height={height} alt={`${brand.name} logo`} priority />;
};
