'use client';

import { TenantLogo } from '@/app/components/TenantLogo';
import { Button } from '@/packages/ui/Button';
import { INPUT_PATTERN, TextInput } from '@/packages/ui/forms/TextInput';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useFormState } from 'react-dom';
import { login } from '@/app/(pages)/account/actions';
import { twMerge } from 'tailwind-merge';
import { useSearchParams } from 'next/navigation';
import { ConfigBrandType } from '@/packages/api/resources/v1/config/brand';
import { TRACK_EVENTS, applicationInsights } from '@/packages/azure/ApplicationInsights';

type Props = {
  brand: ConfigBrandType;
};

export const LoginForm: React.FC<Props> = ({ brand }) => {
  const [state, formAction] = useFormState(login, null);
  const params = useSearchParams();

  useEffect(() => {
    if (state?.message) {
      applicationInsights.trackEvent({
        name: TRACK_EVENTS.Login,
        properties: state,
      });
    }
  }, [state]);

  return (
    <div className="grid justify-items-center gap-8">
      <Link href="/">
        <TenantLogo brand={brand} width={327} height={120} />
      </Link>

      <form className="grid w-full gap-20 lg:w-96" noValidate action={formAction}>
        <input type="hidden" name="redirect" defaultValue={params.get('redirect')?.toString()} />
        <fieldset className="grid gap-5">
          <TextInput name="userName" autoComplete="email" label="Email" placeholder="username@kroger.com" pattern={INPUT_PATTERN.EMAIL} required />

          <TextInput name="password" autoComplete="password" label="Password" pattern={INPUT_PATTERN.PASSWORD} type="password" required />
        </fieldset>

        <div className="grid gap-8">
          <Button type="submit" variant="primary" label="Log in" />

          <Link className="text-center underline" href="/account/forgot-password">
            Can`t remember your password?
          </Link>
        </div>

        {state?.message && <p className={twMerge('text-center', state.error && 'text-red-600')}>{state.message}</p>}
      </form>
    </div>
  );
};
