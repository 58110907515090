'use client';

import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../Icons';
import { debounce } from '@/packages/utils/functions';

export const enum INPUT_PATTERN {
  // EMAIL = '^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$',
  EMAIL = '[a-zA-Z0-9._+%\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}',
  /**
   * Minimum Length: Ensure the password is at least 8 characters long.
   * Upper and Lowercase Letters: Require both uppercase and lowercase letters.
   * Numbers: Include at least one numeral.
   * Special Characters: Include at least one special character (e.g., !@#$%^&*).
   */
  PASSWORD = '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*])[A-Za-z\\d!@#$%^&*]{8,}',
}

type Props = {
  name?: string;
  label?: string;
  type?: 'text' | 'number' | 'password';
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  defaultValue?: string | number;
  autoComplete?: string;
  pattern?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const TextInput: React.FC<Props> = ({ className, label, name, placeholder, autoComplete, pattern, defaultValue, required, disabled, maxLength, min, max, step, onChange, type = 'text' }) => {
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = inputRef.current;
    if (!input) return;

    if (input && input.value) setError(!input.validity.valid);

    if (required) {
      const form = input.closest('form');
      form?.addEventListener('submit', onSubmit);
    }
  }, [inputRef, required]);

  const onSubmit = (event: Event) => {
    const form = event.currentTarget as HTMLFormElement;
    const input = inputRef.current;

    if (input && !form.checkValidity()) {
      event.preventDefault();
      setError(!input.validity.valid);
    }
  };

  const onInput = (event: React.ChangeEvent) => {
    const input = event.target as HTMLInputElement;
    setError(!input.validity.valid);
  };

  return (
    <div
      className={twMerge(
        'relative grid gap-2 rounded-t transition-all',
        error ? 'border-b-2 border-red-600 bg-red-600/5' : 'border-b border-blue-200/30 focus-within:bg-blue-200/5',
        disabled && 'pointer-events-none opacity-50',
        className
      )}
    >
      <label className="truncate p-2 text-12" htmlFor={name}>
        {label}
        {required ? '*' : ''}
      </label>

      <input
        ref={inputRef}
        className={twMerge('flex-1  bg-transparent pb-2 indent-2', error && 'pr-9', disabled && 'pointer-events-none opacity-80')}
        type={type}
        name={name}
        onInput={debounce(onInput, 350)}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        pattern={pattern}
        min={min}
        max={max}
        step={step}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
      />

      <div className="absolute bottom-2 right-2">{error && <Icon type="warning-circle" className="text-red-600" />}</div>
    </div>
  );
};
